/* latin-ext */
@font-face {
  font-family: '__Figtree_d680cf';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/5f8bef30161d4c4f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_d680cf';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/192e369fd43210ef-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_d680cf';src: local("Arial");ascent-override: 93.60%;descent-override: 24.63%;line-gap-override: 0.00%;size-adjust: 101.49%
}.__className_d680cf {font-family: '__Figtree_d680cf', '__Figtree_Fallback_d680cf';font-style: normal
}

